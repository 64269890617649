<template>
    <div>
        <div class="main-title">启动图列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">启动图显示规律新建启动图最靠前</li>
                        <li data-v-69874ad0="">当天存在多张启动图的情况下： 节假日启动图 > 非节假日启动图</li>
                    </ul>
                </div>
            </div>

            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-input v-model="searchData.name" size="mini" placeholder="名称"></el-input>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.isHoliday" clearable placeholder="是否节假日" size="mini">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="2"></el-option>
                    </el-select>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.status" clearable placeholder="状态" size="mini">
                        <el-option label="启用" :value="1"></el-option>
                        <el-option label="禁用" :value="2"></el-option>
                    </el-select>
                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class="fl-r">
                    <router-link to="/startDiagram/add" class="router-link">
                        <el-button type="primary" size="small">新增</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>
            </div>

            <div class="table-content">
                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%; background: #fff">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column property="name" label="名称"> </el-table-column>
                    <el-table-column label="启动图">
                        <template slot-scope="scope">
                            <el-image class="table-item-img" :src="imageUrl + scope.row.pic"
                                :preview-src-list="[imageUrl + scope.row.pic]">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>

                    <el-table-column label="是否假期">
                        <template slot-scope="scope">
                          <span>{{ scope.row.is_holiday ==1?'是':'否' }}</span>
                        </template>
                    </el-table-column>

                  

                  

                    <el-table-column property="address" label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                                @change="setStatus(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column property="start_time" label="开始时间">
                    </el-table-column>
                    <el-table-column property="end_time" label="结束时间">
                    </el-table-column>
                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="updated_at" label="更新时间">
                    </el-table-column>
                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link>
                            </span>
                            -
                            <!-- <span>
                                <el-link v-if="scope.row.status == 1" type="primary"
                                    @click="statusSet(scope.row, scope.$index)">启用</el-link>
                                <el-link v-else type="primary"
                                    @click="statusSet(scope.row, scope.$index)">禁用</el-link>
                            </span> - -->
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "list",
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            imageUrl: config.imageUrl,
            searchData: {},

            tableData: [],
        };
    },
    methods: {
        ...mapActions("startDiagram", [
            "listAppletStartupDiagram",
            "delAppletStartupDiagram",
            "setAppletStartupDiagramStatus",
        ]),

        search() {
            this.tableData = [];
            this.page = 1;
            this.getList();
        },
        async deleted(row, rowIndex) {
            const { data, res_info } = await this.delAppletStartupDiagram(row.id);
            if (res_info != "ok") return;
            this.$message.success("删除成功");
            this.getList();
        },
        editDetail(row) {
            this.$router.push("/startDiagram/edit/" + row.id);
        },
        async setStatus(row) {
            let status = row.status
            let form = {
                id: row.id,
                status
            }
            const { res_info } = await this.setAppletStartupDiagramStatus(form)
            if (res_info != "ok") return;
            this.$message.success("设置成功");
            this.getList();
        },
        getList() {
            this.loading = true;
            let data = {
                page: this.page,
                pageSize: this.pageSize,
                ...this.searchData,
            };
            this.listAppletStartupDiagram(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },
        typeName(type) { },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style scoped>

</style>
